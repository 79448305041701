import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfers', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfers/' + id);
}

const getFiles = async (courseTransferId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfer/files?filter[course_transfer_id]='+courseTransferId+'&limit=-1');
}

const getFile = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfer/files/' + id);
}

const download = async (uuid) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfer/files/download/' + uuid, {responseType: "arraybuffer"});
}

const getFormData = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfers/form');
}

const postFormData = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/course/transfers');
}

const uploadFile = async (fileData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/course/transfer/files', fileData);
}

const deleteFile = async (fileId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfer/files/delete/' + fileId);
}

const addCourse = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/course/transfer/courses', data);
}

const getCourses = async (courseTransferId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfer/courses?filter[course_transfer_id]=' + courseTransferId + '&limit=-1');
}

const deleteCourse = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/course/transfer/courses/' + id);
}

const start = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/course/transfers/' + id + '/start');
}

const studentRequests = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfers/student');
}

const studentTransferredCourses = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/transfers/' + id + '/transferred-courses');
}

const studentApprove = async (id, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/course/transfers/' + id + '/student-approve', data);
}

const complete = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/course/transfers/' + id + '/complete');
}

const take = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/course/transfers/' + id + '/take');
}

const release = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/course/transfers/' + id + '/release');
}

export default {
    getAll,
    get,
    getFiles,
    getFile,
    download,
    addCourse,
    getCourses,
    deleteCourse,
    getFormData,
    postFormData,
    uploadFile,
    deleteFile,
    start,
    studentRequests,
    studentTransferredCourses,
    studentApprove,
    complete,
    take,
    release
}
